footer {
	@extend %layout--full;
	@extend %clearfix--simple;
	background-color: $color__biltema--blue;
	color: $color__white;
	padding: $spacing__vert * 1 0 $spacing__vert * 2 0;

	a {
		@extend %link--white;
	}
}

.footer__section {
	@include section__padding;
}

.footer__disclaimer {
	@extend .align--center;
	@extend %font__size--16;
}
