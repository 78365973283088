
/* JS */

.js__showmore--toggle {
	&.s__active svg {
		transform: rotate(270deg);
		transition: transform ease-in 0.15s;
	}
}

.js__slidedown--toggle {
	svg {
		transform: rotate(90deg);
		transition: transform ease-in 0.15s;
	}

	&.s__active {
		svg {
			transform: rotate(-90deg);
			transition: transform ease-in 0.15s;
		}
	}
}
