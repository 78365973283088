/* MESSAGE */
$message__bgcolor--green: lighten($color__status--green, 46);
$message__color--green: darken($color__status--green, 20);
$message__bgcolor--red: lighten($color__status--red, 42);
$message__color--red: darken($color__status--red, 25);
$message__bgcolor--orange: lighten($color__status--orange, 20);
$message__color--orange: darken($color__status--orange, 32);

.message {
	background-color: $color__grey--light;

	&--green {
		background-color: desaturate($message__bgcolor--green, 40);
		color: $message__color--green;

		* {
			fill: $message__color--green;
			color: $message__color--green !important;
		}
	}

	&--red {
		background-color: desaturate($message__bgcolor--red, 20);
		color: $message__color--red;

		.message__inner > svg {
			fill: $message__color--red;
		}
	}

	&--orange {
		background-color: desaturate($message__bgcolor--orange, 20);
		color: $message__color--orange;

		.message__inner > svg {
			fill: $message__color--orange;
		}
	}

	&--blue {
		background-color: lighten($color__biltema--blue, 54);
		color: darken($color__biltema--blue, 14);

		.message__inner > svg {
			fill: darken($color__biltema--blue, 20);
		}
	}

	&__inner {
		padding: $spacing__vert $grid__margin;
		position: relative;

		> p {
			flex: 1 1 auto;
		}

		> *:last-child {
			margin-bottom: 0;
		}

		> svg {
			position: absolute;
			left: $grid__margin;
		}
	}

	&--has-icon {
		.message__inner {
			padding-left: $grid__margin * 3.5;
		}
	}

	&__fixed {
		z-index: 999;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: $spacing__vert $grid__margin;
	}

	&__small {
		@extend %font__size--14;

		&.message--has-icon {
			.message__inner {
				padding-left: $grid__margin * 2.5;
			}
		}

		.message__inner {
			padding: $spacing__vert / 2 $grid__margin / 2;
		}
	}

	&__inline {
		display: inline-block;
		width: auto;
		clear: right;

		.message--has-icon .message__inner {
			padding-left: $grid__margin * 1.75;

			> svg {
				transform: translateY(-50%);
				top: 50%;
				left: $grid__margin / 2;
			}
		}
	}


	//Message when aded to list
	&__addtolist {
		@extend %font--uppercase;
		@extend %font__futura--bold;
		@extend %font__size--14;
		color: $color__blue--light;
		//position: absolute;
		display: flex;
		align-items: center;
		border: 2px solid $color__blue--light;
		padding: 0 $grid__margin / 2;
	}
}