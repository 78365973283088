.list {
	&__buttonlist {
		@extend %list--clearstyle;
		margin-left: -$grid__margin;
		margin-right: -$grid__margin;

		> li {
			margin: 0 $grid__margin $spacing__vert;
		}
		@include mq('tablet--small') {
			display: flex;

			> li {
				width: 50%;
			}
		}
	}

	&__linklist--horizontal {
		@extend %list--clearstyle;
		margin-left: -$grid__margin;
		margin-right: -$grid__margin;

		a {
			@extend %font--uppercase;
			@extend %font__size--18;
			@extend %font__myriad--semibold;
			display: block;
			padding: $spacing__vert / 2 $grid__margin;
		}
		@include mq('tablet--small') {
			display: flex;
			justify-content: center;
		}
	}
}
