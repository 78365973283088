/* misc utilities classes */

/* text colors */
.s__color {
	&--white {
		color: $color__white;
	}

	&--black {
		color: $color__black;
	}

	&--blue {
		color: $color__biltema--blue;
	}
}

/* status */
.s__lowprice {
	color: $color__status--red;
}
/* spacing */

.margin {
	&__bottom {
		&--0 {
			margin-bottom: 0;
		}

		&--0_5 {
			margin-bottom: $spacing__vert / 2;
		}

		&--1 {
			margin-bottom: $spacing__vert;
		}

		&--2 {
			margin-bottom: $spacing__vert * 2;
		}
	}

	&__top {
		&--zero {
			margin-top: 0;
		}

		&--0_5 {
			margin-top: $spacing__vert / 2;
		}

		&--1 {
			margin-top: $spacing__vert;
		}

		&--2 {
			margin-top: $spacing__vert * 2;
		}
	}

	&__right {
		&--1 {
			margin-right: $grid__margin;
		}
	}

	&__clear {
		margin: 0;

		&--right--left {
			margin-right: 0;
			margin-left: 0;
		}
	}
}

.padding {
	&__left {
		&--0 {
			padding-left: 0 !important;
		}

		&--2 {
			padding-left: $grid__margin * 2;
		}
	}

	&__right {
		&--0 {
			padding-right: 0 !important;
		}
	}

	&__clear {
		padding: 0;
	}
}


/* align classes */
.align {
	&--center {
		text-align: center;
		text-indent: 0 !important;
	}

	&--left {
		text-align: left;
	}
}

/* centering of blocks etc. */
.center {
	margin-left: auto;
	margin-right: auto;
}

/* maxwidth classes in order to limit the amount of stretch. Based on max grid width */

.maxwidth {
	&--33 {
		max-width: $maxwidth--33;
	}

	&--50 {
		max-width: $maxwidth--50;
	}

	&--66 {
		max-width: $maxwidth--66;
	}

	&--75 {
		max-width: $maxwidth--75;
	}
}


/* state classes - use for toggle state, indicate interaction state or appstate */
/* default state */
.s__default {
	&--hidden {
		display: none;

		&.s__show {
			display: block !important;
		}
	}
}

/* disable visability */
.s__visibility {
	&--hidden {
		visibility: hidden;
		position: absolute;
		left: -999em;
	}
}

.s__opened {
	display: block !important;
}

/* expandable classes - normally used togheter with js__dropdown */
.s__expandable {
	max-height: 0;
	overflow: hidden;
	transition: max-height .25s;

	&.s__expanded {
		max-height: 10000px;
		transition: max-height .25s;
	}
}

.s__expandable {
	&--on-mobile {
		@include mq($until: 'tablet--large') {
			max-height: 0;
			overflow: hidden;
			transition: max-height .25s;

			&.s__expanded {
				max-height: 10000px;
				transition: max-height .25s;
			}
		}
	}
}

/* popover */
.s__popover {
	display: none;
	position: absolute;
	z-index: 1;
	left: $grid__margin / 2;
	right: $grid__margin / 2;
	margin-top: $spacing__vert / 4;
	padding: $spacing__vert / 2 $grid__margin / 2;
	background-color: $color__white;
	border: 1px solid #e8e8e6;
	box-shadow: 1px 1px 3px rgba($color__black, 0.35);
}

/* toggle--show */
.s__show {
	display: block;

	&--on-mobile--medium--up {
		display: none;
		@include mq('mobile--medium') {
			display: inline-block;
		}
	}

	&--on-tablet--up {
		display: none;
		@include mq('tablet') {
			display: block;
		}
	}

	&--on-tablet--large--up {
		display: none;
		@include mq('tablet--large') {
			display: block;
		}
	}
}

/* toggle--hide */
.s__hide {
	display: none !important;

	&--on-tablet--up {
		@include mq('tablet') {
			display: none !important;
		}
	}

	&--on-tablet--large--up {
		@include mq('tablet--large') {
			display: none !important;
		}
	}
}


/* Disable - scroll past viewport height, Used in conjuction with menu slides, etc. */
.s__scroll--disabled {
	max-height: 100%;
	overflow: hidden;
}


/* slidepanels */
.s__panel {
	position: absolute;
	top: 0;
	right: -99em;
	z-index: 100;
	width: 100%;
	min-height: 100%;
	transition: right 0.25s;

	&.s__opened {
		right: 0;
		transition: right 0.25s;
	}
	@include mq('tablet--large') {
		display: none;
		left: 0;
		right: 0;
		top: auto;
		min-height: auto;

		&.s__opened {
			display: block;
		}
	}
}



/* rotate */

%s__rotate {
	transition: transform 0.25s;
}

.s__rotate {
	&--0 {
		@extend %s__rotate;
		transform: rotate(0deg);
	}

	&--90 {
		@extend %s__rotate;
		transform: rotate(90deg);
	}

	&--90-neg {
		@extend %s__rotate;
		transform: rotate(-90deg);
	}

	&--180 {
		@extend %s__rotate;
		transform: rotate(180deg);
	}
}

.s__active {
	.s__rotate {
		&--0 {
			transform: rotate(90deg);
		}

		/*&--90 {
			transform: rotate(180deg);
		}*/

		&--90-neg {
			transform: rotate(0deg);
		}
	}
}


/* misc helper classes */


.float {
	&--left {
		float: left;
	}

	&--right {
		float: right;
	}

	&--clear {
		float: none;
		clear: both;
	}
}

.list {
	&--clearstyle {
		list-style: none;
		padding-left: 0;
	}
}


/* links */

.link {
	&--blue {
		@extend %link--blue;

		&--underline {
			@extend %link--blue;
			text-decoration: underline;
		}
	}

	&--white {
		@extend %link--white;
	}

	&--black {
		@extend %link--black;

		&--underline {
			@extend %link--black;
			text-decoration: underline;
		}
	}
}

.border {
	&--radius {
		border-radius: $radius;
	}
}

/* text styles */

.text {
	&--bold {
		@extend %font__myriad--bold;
	}

	&--semibold {
		@extend %font__myriad--semibold;
	}

	&--small {
		@extend %font__size--16;
	}

	&--xsmall {
		@extend %font__size--14;
	}
}

//widths

.width {
	&--100 {
		width: 100%;
	}
}

//print
.s__noprint {
	@media print {
		display: none !important;
	}
}