/* IE specific hacks */

/* Below method described here:
https://paper-leaf.com/blog/2014/09/targeting-ie-10-11-browsers-css/ 
*/
/*
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
    /* IE10+ specific styles go here *//*
.nav__support--desktop, 
    .button__shoppinglist {
        transform: translateY(-50%);
    }
 }
 */

/*
 @media all and (min-width: 1920px) and (-ms-high-contrast:active), (-ms-high-contrast:none) {
    .nav__support--desktop, 
    .button__shoppinglist {
        transform: translateY(-50%);
    }
}*/

/*
 @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
    /* IE10+ specific styles go here */
/*.nav__support--desktop, 
    .button__shoppinglist {
        transform: translateY(-50%);
    }
 }
*/

/*
https://jeffclayton.wordpress.com/2014/07/22/internet-explorer-css-hacks-collection/
*/
@media screen and (min-width: 1024px) {
	_:-ms-input-placeholder, :root .nav__support--desktop, :root .button__shoppinglist {
		transform: translateY(-50%);
	}
}

_:-ms-input-placeholder, :root {
	//weirdness with pos: rel and flex-box
	.swiper-lazy-preloader {
		display: none;
	}
	//Objects-fit issues
	.block__img, .page__featimage img {
		min-height: auto !important;
		max-height: auto !important;
		height: auto !important;
	}

	//button height --vertical height issues on nested flexbox
	button, .button {
		height: $button__height / 2;
	}

	.button__s {
		height: $button__height / 1.3;
	}

	.button__m {
		height: $button__height;
	}
}