.article {
	background-color: $color__white;
	margin-top: -$spacing__vert;

	&__container {
		padding: 0 $grid__margin;
	}

	&__img {
		picture, img {
			min-width: 100%;
		}
	}

	&__heading {
		@include section__padding;
		box-shadow: 2px 0px 2px -1px #ccc, -2px 0px 2px -1px #ccc, 0 -1px 3px -1px #ccc;
		position: relative;
		padding-top: $spacing__vert * 1.5;
		padding-bottom: $spacing__vert * 1.75;

		h1 {
			margin-bottom: 0;
		}

		&:after {
			content: " ";
			position: absolute;
			bottom: -2px;
			left: 0;
			height: 3px;
			width: 100%;
			background-color: #fff;
		}
	}

	&__section {
		@include section__padding;
		margin-bottom: $spacing__vert * 3;
	}
}
