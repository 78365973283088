.icon {
	width: $icon__size--m;
	height: $icon__size--m;

	//MARGIN
	&__right {
		margin-left: $grid__margin / 2;
	}

	&__left {
		margin-right: $grid__margin / 2;
	}

	//SIZES
	&__xxs {
		width: $icon__size--xxs;
		height: $icon__size--xxs;
	}

	&__xs {
		width: $icon__size--xs;
		height: $icon__size--xs;
	}

	&__s {
		width: $icon__size--s;
		height: $icon__size--s;

		&--radron {
			width: $icon__size--s * 3;
		}
	}

	&__m {
		width: $icon__size--m;
		height: $icon__size--m;

		&--radron {
			width: $icon__size--m * 3;
		}
	}

	&__l {
		width: $icon__size--l;
		height: $icon__size--l;

		&--radron {
			width: $icon__size--l * 3;
		}
	}

	&__xl {
		width: $icon__size--xl;
		height: $icon__size--xl;

		&--radron {
			width: $icon__size--xl * 3;
		}
	}

	&__xxl {
		width: $icon__size--xxl;
		height: $icon__size--xxl;
	}

	//COLORS
	&__blue {
		fill: $color__biltema--blue;

		&--light {
			fill: $color__blue--light;
		}
	}

	&__green {
		fill: $color__status--green;
	}

	&__red {
		fill: $color__status--red;
	}

	&__orange {
		fill: $color__status--orange;
	}

	&__white {
		fill: $color__white;
	}

	&__facebook {
		fill: $color__socialmedia--facebook;
	}

	&__googleplus {
		fill: $color__socialmedia--googleplus;
	}
}
