/* local grid */
.grid {
	&__col--50 {
		width: 100%;
	}
	@include mq('tablet--small') {
		margin-left: -$grid__margin;
		margin-right: -$grid__margin;
		display: flex;

		&__col--50 {
			width: 50%;
			padding: 0 $grid__margin;
		}
	}
}
