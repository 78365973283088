.logo {
	width: 220px;
	display: block;

	&--img {
		width: 100%;
	}
}

@include mq('tablet') {
	.logo {
		width: 280px;
	}
}
