/* stylepattern classes - use with kitchensink */

.pattern__container {
	@extend %layout--full;
	padding: $spacing__vert $spacing__horz * 2;
}

.pattern__icons {
	@extend %list--clearstyle;
	background-color: $color__grey--light;
	padding: $spacing__vert $spacing__horz;

	> li {
		display: inline-block;
		width: $icon__size--m;
		height: $icon__size--m;
		margin: $spacing__horz / 2;
	}

	&--background--blue {
		background-color: $color__biltema--blue !important;
	}

	&--background--grey {
		background-color: $color__grey--dark !important;
	}

	&--background--orange {
		background-color: $color__orange !important;
	}
}
