/* forms */

/* base form styles */

input[type="search"], input[type="number"], input[type="email"], input[type="password"], input[type="text"], input[type="tel"], textarea {
	@extend %font__myriad;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-color: $color__white;
	border: 1px solid $color__grey--light;
	min-height: $button__height;
	text-indent: 1rem;
	border-radius: 0;
}

input[type="search"], input[type="email"], input[type="password"], input[type="text"], input[type="tel"], textarea {
	width: 100%;
}

input[type="submit"], button {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
}

input[type="number"] {
	-moz-appearance: textfield;

	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

input[type="text"], input[type="password"], input[type="search"], input[type="number"], input[type="email"], input[type="tel"] {
	&::placeholder {
	}

	&.s__invalid {
		border: 2px solid $color__status--red;
	}
}

input[type="radio"], input[type="checkbox"] {
	margin-right: $grid__margin / 2;
	width: $button__height / 2.5;
	height: $button__height / 2.5;
}

textarea {
	padding-top: $spacing__vert / 2;
	min-height: 6rem;
}

/* custom form styles */

.form {
	&__container {
		display: flex;

		> *:not(:last-child) {
			margin-right: $grid__margin / 2;
			margin-bottom: $spacing__vert / 2;
		}
	}

	&__list {
		@extend %list--clearstyle;
		@extend %clearfix--simple;
		margin-bottom: $spacing__vert / 2;

		> li {
			margin-bottom: $spacing__vert / 2;
			display: block;
		}

		label {
			display: flex;
			align-items: center;
		}
	}

	&__field {
		text-indent: 1rem;

		&--quantity, &--quantity--small, &--quantity--xsmall {
			@extend %font__myriad--bold;
			text-align: center;
			text-indent: 0 !important;
			max-width: 80px;
			width: 60px;
		}

		&--quantity--small, &--quantity--xsmall {
			min-height: 2rem !important;
		}

		&--quantity--small {
			max-width: 60px;
		}

		&--quantity--xsmall {
			max-width: 40px;
		}
	}

	&__label {
		margin-bottom: $spacing__vert / 4;
		display: inline-block;
	}
}

/* form - subclasses */
.form__redeemcode {
	display: flex;
	flex-direction: column;

	> * {
		margin-bottom: $spacing__vert / 2;
	}

	> *:last-child {
		width: 100%;
	}
	@include mq('tablet--small') {
		flex-direction: row;

		> *:last-child {
			width: auto;
			min-width: 12rem;
		}
	}
}

.form__newsletter {
	flex-direction: column;

	> *:last-child {
		width: 100%;
	}
	@include mq('mobile--medium') {
		flex-direction: row;

		> *:last-child {
			width: auto;
			margin-bottom: $spacing__vert / 2;
		}
	}
}

