/* Global base styles - used site wide */
* {
	box-sizing: border-box;
}

html {
	font-size: 100%;
	line-height: 1.5;
}

body {
	font-family: $font__stack--base;
	font-size: $font__base--unit;
	line-height: $font__base--lineheight;
	overflow-y: scroll;
}

//Hide SVGspritecontainer
body > div:first-child {
	visibility: hidden;
	position: absolute;
	left: -999em;
}

main {
	padding-bottom: $spacing__vert;
}

img {
	max-width: 100%;
	display: block;

	&.float--left {
		margin-right: $grid__margin;
	}

	&.float--right {
		margin-left: $grid__margin;
	}
}

p {
	margin-top: 0;
	margin-bottom: $spacing__vert;
}

a {
	@extend %link--blue;
	text-decoration: underline;
}

small {
	font-size: 0.75rem;
	line-height: 1.25;
}

h1, h2, h3, h4, h5, h6 {
	@extend %font__myriad--semibold;
}

h1 {
	@extend %font__h1;
}

h2 {
	@extend %font__h2;
}

h3 {
	@extend %font__h3;
}

h4 {
	@extend %font__h4;
}

figure {
	padding: 0;
	margin: 0;
}

img {
	max-width: 100%;

	&.float--right, &.float--left {
		max-width: 50%;
	}
}

strong {
	font-weight: $font--bold;
}

hr {
	border-style: none;
	height: 0;
	border-bottom: 1px solid #000;

	&.hr--light {
		border-bottom: 1px solid $color__grey--light;
		//-webkit-margin-before: 0;
	}
}

em {
	font-style: italic;
}

ul, ol {
}

blockquote {
	@extend cite;
	margin: $spacing__vert / 2 0;
}

cite {
	font-style: italic;
}

form {
}

select, button, input {
	font-family: $font__stack--base;
}

button, input[type="button"] {
}

input {
	&[type="submit"], &[type="search"], &[type="number"], &[type="email"] {
	}
}

textarea {
}

iframe {
	@extend .center;
	max-width: 100%;
}
