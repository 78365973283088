/* Global placeholderstyles - used across the project */

/* layout */
%layout--container {
	width: 100%;
	position: relative;
	max-width: $site__maxwidth;
	margin: 0 auto;
}

%layout--full {
	width: 100%;
	position: relative;
}

%layout--grid {
	@extend %layout--container;
	@include mq('tablet') {
		padding: 0 $grid__margin / 2;
	}
}


/* list styles */
%list--clearstyle {
	list-style: none;
	margin: 0;
	padding: 0;
}

%list--inline-block {
	font-size: 0;  /* Hack to fix spacing issue for inline-block elements - note: child element needs new fontsize declaration */

	> li {
		display: inline-block;
	}
}

/* link styles */
%link--white {
	color: $color__white;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: underline;
	}
}

%link--blue {
	color: $color__biltema--blue;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: underline;
	}
}

%link--black {
	color: $color__black;
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: underline;
	}
}

/* Link style for menu lists */
%link--listnavitem {
	color: $color__black;
	text-decoration: none;
	display: block;
	min-height: $button__height;
	width: 100%;
	padding: $spacing__vert / 2.5 $button__width 0 $grid__margin / 2;
}

/* nav header style - for overlaymenus, mobilemenues etc.*/
%nav__header {
	border-bottom: 3px solid $color__black;
	min-height: $button__height;
	position: relative;

	> button {
		position: absolute;
		left: 0;
		right: auto;

		&:last-child {
			left: auto;
			right: 0;
		}
	}
}

/* spacing */
%margin__bottom--0_5 {
	margin-bottom: $spacing__vert / 2;
}

%margin__bottom--1 {
	margin-bottom: $spacing__vert;
}


/* centering */

%center--vertical {
	display: flex;
	align-items: center;
}

/* overlay */
%overlay--background {
	background-color: rgba($color__black, 0.45);
}

/* */

/* utilities */

%clearfix {
	&:after {
		content: ".";
		visibility: hidden;
		display: block;
		height: 0;
		clear: both;
	}

	&--easy {
		&:after {
			content: ".";
			visibility: hidden;
			display: block;
			height: 0;
			clear: both;
		}
	}

	&--simple {
		overflow: hidden;
	}
}


/* forms */
%select--clearstyle {
	appearance: none;
	border: 0;

	option {
		appearance: none;
	}
}
