%select--base {
	appearance: none;
	height: $button__height;
	border: none;
	line-height: $button__height;
	text-indent: $grid__margin / 4;
	box-sizing: border-box;
}

%select__container--base {
	position: relative;

	> .select {
		background: none;
	}
}

select {
	border: 0 !important;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;

	&::-ms-expand {
		display: none;
	}
}

.select {
	@extend %select--base;
	width: 100%;
	padding-right: $button__height;
	text-indent: 1rem;
}

.select__container {
	&--grey {
		@extend %select__container--base;
		border: 1px solid $color__grey--light;

		.select__icon {
			background-color: $color__grey--light;
		}
	}

	&--narrow {
		max-width: 150px;

		> select {
			text-indent: 2.5rem;
		}
	}
}

.select__icon {
	height: $button__height;
	width: $button__height;
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
}
