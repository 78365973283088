/* general typography styles */

/* font placeholders */
%font__futura--bold {
	font-family: $font__futura--bold;
	font-weight: $font--bold;
	font-style: normal;
}

%font__futura--bold-italic {
	font-family: $font__futura--bold;
	font-weight: $font--bold;
	font-style: italic;
}

%font__ff-market--regular {
	font-family: $font__ff-market--web;
	font-style: normal;
	font-weight: $font--regular;
	font-feature-settings: "ss08";
	font-variant-ligatures: contextual;
}

%font__myriad {
	font-family: $font__myriad;
}

%font__myriad--semibold {
	font-family: $font__myriad;
	font-weight: $font--semibold;
}

%font__myriad--bold {
	font-family: $font__myriad;
	font-weight: $font--bold;
}

%font__franklin-gothic--heavy {
	font-family: $font__franklin-gothic;
	font-style: normal;
	font-weight: $font--heavy;
}

/* headings sizes base placeholders */

%font__h1 {
	@include font__size(36, 0.9);
	margin: 0 0 $spacing__vert 0;
}

%font__h2 {
	@include font__size(28);
	margin: 0 0 $spacing__vert 0;
}

%font__h3 {
	@include font__size(24);
	margin: 0 0 $spacing__vert * 0.85 0;
}

%font__h4 {
	@include font__size(18);
	margin: 0 0 $spacing__vert / 1.25 0;
}

/* Font base sizes */

%font__size--14 {
	@include font__size(14);
}

%font__size--16 {
	@include font__size(16);
}

%font__size--18 {
	@include font__size(18);
}

%font__size--20 {
	@include font__size(20, 1.2);
}

%font__size--24 {
	@include font__size(24);
}


/* font base styles */
%font__weight--normal {
	font-weight: 400;
}

%font--uppercase {
	text-transform: uppercase;
}

%font--lowercase {
	text-transform: lowercase;
}

/* FONT utility classes */

/* webfont classes */
.font {
	&__futura--bold {
		@extend %font__futura--bold;
	}

	&__myriad--regular {
		@extend %font__myriad;
	}

	&__myriad--semibold {
		@extend %font__myriad--semibold;
	}

	&__myriad--bold {
		@extend %font__myriad--bold;
	}

	&__ff-market--regular {
		@extend %font__ff-market--regular;
	}

	&__franklin-gothic--heavy {
		@extend %font__franklin-gothic--heavy;
	}

	&--lowercase {
		@extend %font--lowercase;
	}

	&--uppercase {
		@extend %font--uppercase;
	}
}


/*  heading classes */
.heading {
	&--l {
		@include font__size(28, 1);
		margin: 0 0 $spacing__vert 0;
	}

	&--m {
		@include font__size(24, 1);
		margin: 0 0 $spacing__vert 0;
	}

	&--s {
		@include font__size(20);
		margin: 0 0 $spacing__vert * 0.85 0;
	}

	&--xs {
		@include font__size(16);
		margin: 0 0 $spacing__vert * 0.25 0;
	}
	@include mq('tablet') {
		&--l {
			@include font__size(36, 0.9);
			margin: 0 0 $spacing__vert 0;
		}

		&--m {
			@include font__size(28, 1);
			margin: 0 0 $spacing__vert 0;
		}

		&--s {
			@include font__size(24);
			margin: 0 0 $spacing__vert * 0.85 0;
		}
	}
}
