/* GLOBAL MIXINS */

@mixin font__size($font-size: 16, $lineheight: 1) {
	@if $font-size == 10 {
		font-size: .625rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 12 {
		font-size: .75rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 14 {
		font-size: .875rem;
		line-height: $lineheight * 1.5;
	} @else if $font-size == 16 {
		font-size: 1rem;
		line-height: $lineheight * 1.5;
	} @else if $font-size == 18 {
		font-size: 1.125rem;
		line-height: $lineheight * 1.50;
	} @else if $font-size == 20 {
		font-size: 1.25rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 22 {
		font-size: 1.375rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 24 {
		font-size: 1.5rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 26 {
		font-size: 1.625rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 28 {
		font-size: 1.75rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 30 {
		font-size: 1.875rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 32 {
		font-size: 2rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 34 {
		font-size: 2.125rem;
		line-height: $lineheight * 1.5;
	} @else if $font-size == 36 {
		font-size: 2.25rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 38 {
		font-size: 2.375rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 40 {
		font-size: 2.5rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 42 {
		font-size: 2.625rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 44 {
		font-size: 2.75rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 46 {
		font-size: 2.875rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 48 {
		font-size: 3rem;
		line-height: $lineheight * 1.25;
	} @else if $font-size == 54 {
		font-size: 3.375rem;
		line-height: $lineheight * 1;
	}
}

//font size selector mixin


//SPACING


@mixin grid__spacing--horz($type: margin) {
	#{$type}-left: $grid__margin;
	#{$type}-right: $grid__margin;
}



//MQ

//Depracated as we now use MQ mixin in vendor folder. Remove when confirmed that we don't have any mq__base mixins left
$breakpoints: (
  	"phone":         400px, "phone--wide":   480px, "phablet":       560px, "tablet--small": 640px, "tablet":        768px, "tablet--medium":	849px, "tablet--wide":  1024px, );

@mixin mq__base($width, $type: min) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);

		@if $type == max {
			$width: $width - 1px;
		}

		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}



//rotation
