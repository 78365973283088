/* buttons */

/* local placeholder styles */
%button--base {
	appearance: none;
	min-height: $button__height;
	min-width: $button__width;
	border: none;
	border-radius: 0;
	font-family: $font__stack--base;
	font-size: $font__unit--14;
	line-height: 1.35;
	font-style: normal;
	text-transform: uppercase;
	background: transparent;
	padding: $button__spacing--horz $grid__margin;
	text-align: center;
	cursor: pointer;
	display: inline-flex;
	align-self: center;
	justify-content: center;
	text-decoration: none;
	position: relative;
	color: $color__black;

	> span, > svg {
		align-self: center;
		line-height: inherit;
	}
}

%button__transparent--base {
	border-width: 1px;
	border-style: solid;
	text-decoration: none;
}

%button__centered {
	margin-left: auto;
	margin-right: auto;
	display: table;
}

//button base styles

button, .button {
	@extend %button--base;

	&:hover, &:focus {
		text-decoration: underline;
	}
}

.button__rounded {
	border-radius: $rounded;
}

.button__radius {
	border-radius: $radius;
}

//button text should be wrapped with this class
.button__copy {
	margin: 0;

	&--left {
		margin-right: $button__spacing--horz / 1.5;
	}

	&--right {
		margin-left: $button__spacing--horz / 1.5;
	}
}

//button size classes - if size needs to be modified from base styles
.button {
	&__s {
		min-height: $button__height / 1.3;
		min-width: $button__width / 1.3;
		padding: $button__spacing--horz / 2 $button__spacing--horz / 2;
	}

	&__m {
		min-height: $button__height;
		min-width: $button__width;
		padding: $button__spacing--horz $grid__margin * 2;
	}

	&__l {
		min-height: $button__height * 1.25;
		min-width: $button__width;
		padding: $button__spacing--horz $grid__margin * 2;
	}
}


//clear margin/padding

//buttons with transparent backgrounds and outline
.button__transparent {
	&--black {
		@extend %button__transparent--base;
		border-color: $color__black;
		color: $color__black;
	}

	&--white {
		@extend %button__transparent--base;
		border-color: $color__white;
		color: $color__white;
	}

	&--blue {
		@extend %button__transparent--base;
		border-color: $color__biltema--blue;
		color: $color__biltema--blue;

		&--light {
			@extend %button__transparent--base;
			border-color: $color__blue--light;
			color: $color__blue--light;
		}
	}
}


//buttons with solid backgrounds
%button__solid--transition {
	transition: background-color 0.15s;

	&:hover, &:focus {
		transition: background-color 0.25s;
	}
}

.button__solid {
	&--orange {
		@extend %button__solid--transition;
		background-color: $color__orange;
		color: $color__white;

		&:hover, &:focus {
			background-color: darken($color__orange, 05);
		}
	}

	&--blue {
		@extend %button__solid--transition;
		background-color: $color__biltema--blue;
		color: $color__white;

		&:hover, &:focus {
			background-color: darken($color__biltema--blue, 05);
		}

		&--carmc {
			@extend %button__solid--transition;
			background-color: $color__blue--carmc;
			color: $color__white;

			&:hover, &:focus {
				background-color: darken($color__blue--carmc, 05);
			}
		}

		&--light {
			@extend %button__solid--transition;
			background-color: $color__blue--light;
			color: $color__white;

			&:hover, &:focus {
				background-color: darken($color__blue--light, 05);
			}
		}
	}

	&--white {
		background-color: $color__white;
		color: $color__biltema--blue;
	}

	&--darkgrey {
		@extend %button__solid--transition;
		background-color: $color__grey--dark;
		color: $color__white;

		&:hover, &:focus {
			background-color: darken($color__grey--dark, 05);
		}
	}

	&--lightgrey {
		@extend %button__solid--transition;
		background-color: $color__grey--light;
		color: $color__black;

		&:hover, &:focus {
			background-color: darken($color__grey--light, 05);
		}
	}

	&--lightgrey--2 {
		@extend %button__solid--transition;
		background-color: $color__grey--light--2;
		color: $color__black;

		&:hover, &:focus {
			background-color: darken($color__grey--light--2, 02);
		}
	}
}


//button position
.button__full {
	display: flex;
	width: 100%;
}

button.button__center {
	@extend %button__centered;
}

a.button__center {
	@extend %button__centered;
	//max-width: $button__maxwidth;
}


//specific button styles
.button__menu, .button__shoppinglist {
	position: absolute;
	top: 0;
}

.button__menu {
	left: 0;
}

.button__shoppinglist {
	right: 0;
	@include mq('tablet--large') {
		top: auto;
	}
}

.button__search {
	padding: 0;
	right: 0;
	top: 0;
	position: absolute;
	height: $button__height;
	width: $button__width;
}

.button__search--close {
	width: auto;
	height: $button__height;
	position: absolute;
	top: 0;
	right: 0;
}

.button__back, .button__close {
	padding-left: $button__spacing--horz;
	padding-right: $button__spacing--horz;
}

.button__expand--categories, .button__expand {
	@extend %font__myriad--semibold;
	font-size: $font__unit--18;
	width: 100%;
	padding: 0;
	justify-content: space-between;
	align-items: center;
	position: relative;

	&:hover, &:focus {
		text-decoration: none;
	}
}

.button__expand {
	&--package {
		padding-left: $button__width + $button__spacing--horz;
	}
}

.button__expand--filter {
	width: 100%;

	.button__label--open {
		display: none;
	}

	&.s__active {
		.button__label--open {
			display: block;
		}

		.button__label--default {
			display: none;
		}
	}
	@include mq('tablet--small') {
		width: auto;
	}
}

.button__togglefilter {
	@extend %font__size--16;
	padding: $spacing__vert / 4 $grid__margin / 2;
	justify-content: flex-start;
	text-transform: none;
	min-height: $button__height / 1.5;

	*:last-child {
		float: right;
	}

	&.s__selected {
		opacity: 0.25;
	}
}

.button__closefilter {
	@extend %font__size--16;
	text-transform: none;
	padding: 0 $grid__margin;
	margin: 0 ($grid__margin / 2) $spacing__vert / 2;

	> *:not(:first-child) {
		margin-left: $grid__margin / 2;
	}
}

.button__showmore {
	display: block;

	svg {
		display: block;
		margin: 0 auto;
	}
}

.button__addtocart {
	&--wide {
		@extend %font__futura--bold;
	}
}

.button__addtolist {
	&:active {
		animation: push 0.15s ease-in 1;
	}
}

.button__strong {
	@extend %font__futura--bold;
}

.button__clear--package {
	position: absolute;
	left: 0;
	padding: 0;
	border-right: 1px solid $color__grey--light;
}

.button__overlay {
	position: fixed;
	top: $button__height * 5;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 10;
	background-color: rgba($color__black, 0.5);
}

.button__submit {
	min-width: $button__width * 2;
}

//socialmedia styles
.button__socialmedia {
	&--facebook {
		background-color: $color__socialmedia--facebook;
		color: $color__white;
	}

	&--googleplus {
		background-color: $color__socialmedia--googleplus;
		color: $color__white;
	}
}

//button comps
%button__iconcontainer--base {
	min-width: $button__width;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
}

.button__iconcontainer {
	@extend %button__iconcontainer--base;
	background: transparent;

	&--grey {
		@extend %button__iconcontainer--base;
		background-color: $color__grey--light;
	}

	&--blue {
		@extend %button__iconcontainer--base;
		background-color: $color__blue--carmc;
	}
}

%button__dropdown--base {
	justify-content: flex-start;
	position: relative;
	padding-right: $button__width * 1.5;
}

.button__dropdown {
	&--grey {
		@extend %button__dropdown--base;
		border: 1px solid $color__grey--light;
		background-color: $color__white;
	}
}

.button__container {
	width: 100%;

	> * {
		width: 100%;
		margin-bottom: $spacing__vert / 2;
	}
	@include mq('mobile--medium') {
		width: auto;

		> * {
			width: auto;
			margin-bottom: 0;

			&:not(:last-child) {
				margin-right: $grid__margin;
			}
		}
	}
}
